import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";
import ReactPixel from "react-facebook-pixel";

const ProductInfo = ({ productInfo, onBuyNow }) => {
    const dispatch = useDispatch();
    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const handleAddToCart = () => {
        dispatch(
            addToCart({
                _id: productInfo._id,
                productName: productInfo.productName,
                quantity: 1,
                promo: productInfo.promo,
                image: productInfo.img,
                badge: productInfo.badge,
                price: productInfo.price,
                colors: productInfo.color,
            })
        );

        ReactPixel.track("AddToCart", {
            content_name: productInfo.productName,
            content_category: productInfo.category,
            content_ids: [productInfo._id],
            content_type: "product",
            value: productInfo.price,
            currency: "DT",
        });
    };

    return (
        <div className="flex flex-col gap-5">
            <h2 className="text-4xl font-semibold">{productInfo.productName}</h2>
            <div className="flex-row flex items-center">
                {productInfo.promo ? (
                    <>
                        <p className="text-[#767676] text-xl font-semibold mr-2 line-through">
                            {Math.round(productInfo.price / (1 - productInfo.promo / 100))}DT
                        </p>
                        <p className="text-red-500 text-xl font-semibold">
                            {productInfo.price}DT
                        </p>
                    </>
                ) : (
                    <p className="text-xl font-semibold">{productInfo.price}DT</p>
                )}
            </div>

            <p className="font-medium text-lg">
                <span className="font-normal">Couleur: </span> {productInfo.color}
            </p>

            <p
                className="text-base text-gray-600"
                dangerouslySetInnerHTML={{
                    __html: showFullDescription
                        ? productInfo.des
                        : `${productInfo.des.substring(0, 300)}...`
                }}
            ></p>


            {productInfo.des.length > 100 && (
                <button
                    onClick={toggleDescription}
                    className="text-primeColor font-semibold hover:underline"
                >
                    {showFullDescription ? "Afficher moins" : "Afficher plus"}
                </button>
            )}

            <button
                onClick={handleAddToCart}
                className="w-full py-4 bg-primeColor hover:bg-black duration-300 text-white text-lg font-titleFont"
            >
                Ajouter au Panier
            </button>

            {/* New "Buy Now" Button */}
            <button
                onClick={onBuyNow}
                className="w-full py-4 mt-4 bg-green-500 hover:bg-green-700 duration-300 text-white text-lg font-titleFont"
            >
                Acheter Maintenant
            </button>
        </div>
    );
};

export default ProductInfo;
