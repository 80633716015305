import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    createRoutesFromElements,
    Route,
    ScrollRestoration,
    useLocation,
} from "react-router-dom";
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import Home from "./pages/Home/Home";
import Payment from "./pages/payment/Payment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import ReactPixel from 'react-facebook-pixel';
import { useEffect } from "react";

// Initialize Facebook Pixel
const pixelId = '1275162180341084';
ReactPixel.init(pixelId);

const Layout = () => {
    const location = useLocation();

    // Track page views on route changes
    useEffect(() => {
        ReactPixel.pageView();
    }, [location]);

    return (
        <div>
            <Header />
            <HeaderBottom />
            <SpecialCase />
            <ScrollRestoration />
            <Outlet />
            <Footer />
            <FooterBottom />
        </div>
    );
};

// Define routes and router
const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route path="/" element={<Layout />}>
                <Route path="/product/:productId" element={<ProductDetails />} />
                <Route index element={<Home />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/product/:_id" element={<ProductDetails />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/paymentgateway" element={<Payment />} /></Route>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="*" element={<Home />} />

        </Route>
    )
);

function App() {
    return (
        <div className="font-bodyFont">
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
