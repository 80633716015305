import React from "react";
import { BsSuitHeartFill } from "react-icons/bs";
import { MdOutlineLabelImportant } from "react-icons/md";
import Image from "../../designLayouts/Image";
import Badge from "./Badge";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";
import ReactPixel from 'react-facebook-pixel';

const Product = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _id = props._id;

    // Generate a unique ID based on the product name
    const idString = (_id) => {
        return String(_id).toLowerCase().split(" ").join("");
    };
    const rootId = idString(_id);

    const handleProductDetails = () => {
        navigate(`/product/${rootId}`, {
            state: {
                item: props,
            },
        });
    };

    const handleAddToCart = () => {
        // Dispatch the add to cart action
        dispatch(
            addToCart({
                _id: props._id,
                productName: props.productName,
                promo: props.promo,
                quantity: 1,
                image: props.img,
                badge: props.badge,
                price: props.price,
                colors: props.color,
                description: props.des,
            })
        );

        // Track the "Add to Cart" event with Facebook Pixel
        ReactPixel.track("AddToCart", {
            content_name: props.productName,
            content_category: props.category, // You can add a category prop if available
            content_ids: [props._id],
            content_type: "product",
            value: props.price,
            currency: "DT" // Assuming DT for Dinar; change to your currency
        });
    };

    return (
        <div className="w-full relative group">
            <div className="max-w-80 max-h-80 relative overflow-y-hidden ">
                <div>
                    <Image className="w-full h-full" imgSrc={props.img} />
                </div>
                <div className="absolute top-6 left-8">
                    {props.badge && <Badge text="New" />}
                </div>
                <div className="absolute top-6 left-8">
                    {props.promo && <Badge text="Promo " />}
                </div>
                <div className="w-full h-22 absolute bg-[rgba(0,0,0,0.4)] -bottom-[130px] group-hover:bottom-0 duration-700">
                    <ul className="w-full h-full flex flex-col items-end justify-center gap-2 font-titleFont px-2 border-l border-r">
                        <li
                            onClick={handleAddToCart}
                            className="text-white hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 pt-1 duration-300 w-full"
                        >
                            Ajouter Au Panier
                            <span>
                <BsSuitHeartFill />
              </span>
                        </li>

                        <li
                            className="text-white hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
                            onClick={handleProductDetails}
                        >
                            Afficher Les Détails
                            <span>
                <MdOutlineLabelImportant />
              </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
                <div className="flex items-center justify-between font-titleFont">
                    <h2 className="text-lg text-primeColor font-bold">
                        {props.productName}
                    </h2>
                    <div className={"flex-row flex items-center"}>
                        {props.promo ? (
                            <>
                                <p className="text-[#767676] text-[14px] mr-2 line-through">
                                    {Math.round(props.price / (1 - props.promo / 100))}DT
                                </p>
                                <p className="text-red-500 text-[14px]">{props.price}DT</p>
                            </>
                        ) : (
                            <p className="text-[#767676] text-[14px]">{props.price}DT</p>
                        )}
                    </div>
                </div>
                <div>
                    <p className="text-[#767676] text-[14px]">{props.color}</p>
                </div>
            </div>
        </div>
    );
};

export default Product;
