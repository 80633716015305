import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Slider from "react-slick";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import SliderItems from "../../components/home/SliderItems/SliderItems";
import Payment from "../payment/Payment";
import SampleNextArrow from "../../components/home/SliderItems/SampleNextArrow";
import SamplePrevArrow from "../../components/home/SliderItems/SamplePrevArrow";
import { bestSeller, paginationItems } from "../../constants";
import product from "../../components/home/Products/Product";
import productInfo from "../../components/pageProps/productDetails/ProductInfo";

// Countdown Timer Component
const CountdownTimer = ({promo}) => {
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    // Check if there's already a saved target time in localStorage
    let targetDate = localStorage.getItem("promoEndTime");

    // If no saved target time, set a new one for 5 hours from now
    if (!targetDate) {
      targetDate = new Date();
      targetDate.setHours(targetDate.getHours() + 25);

      localStorage.setItem("promoEndTime", targetDate);
    } else {
      // Parse the saved date if it exists
      targetDate = new Date(targetDate);
    }

    const updateCountdown = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        localStorage.removeItem("promoEndTime"); // Remove expired time
      }
    };

    const timer = setInterval(updateCountdown, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
      <div className="bg-green-500 duration-300 p-4 rounded-lg text-center mb-4">
        <p className="font-semibold font-light mb-2 text-white ">Faites vite ! La Promotion {promo}% Se Termine Dans :</p>
        <div className="flex justify-center space-x-4 text-lg font-bold">
          <div className="flex text-white flex-col items-center">
            <span>{String(timeLeft.days || "00").padStart(2, '0')}</span>
            <span>Jours</span>
          </div>
          <div className="flex text-white flex-col items-center">
            <span>{String(timeLeft.hours || "00").padStart(2, '0')}</span>
            <span>Heures</span>
          </div>
          <div className="flex text-white flex-col items-center">
            <span>{String(timeLeft.minutes || "00").padStart(2, '0')}</span>
            <span>Minutes</span>
          </div>
          <div className="flex text-white flex-col items-center">
            <span>{String(timeLeft.seconds || "00").padStart(2, '0')}</span>
            <span>Secondes</span>
          </div>
        </div>
      </div>
  );
};

const ProductDetails = () => {
  const location = useLocation();
  const { productId } = useParams();
  const [productInfo, setProductInfo] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const allProducts = useMemo(() => paginationItems, []);

  useEffect(() => {
    if (location.state?.item) {
      setProductInfo(location.state.item);
    } else if (productId) {
      const product = allProducts.find((item) => item._id === productId);
      setProductInfo(product);
    }
  }, [location.state, productId, allProducts]);

  const openPaymentModal = () => setIsPaymentModalOpen(true);
  const closePaymentModal = () => setIsPaymentModalOpen(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  if (!productInfo) {
    return <div>Loading product details...</div>;
  }

  return (
      <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
        <div className="max-w-container mx-auto px-4">
          <div className="xl:-mt-10 -mt-7">
            <Breadcrumbs title={productInfo.productName} prevLocation="shop" />
          </div>

          {/* Countdown Timer */}
          <CountdownTimer promo={productInfo.promo} />

          <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-2">
            <div className="h-full xl:col-span-2">
              <Slider {...settings}>
                {productInfo.images?.map((image, index) => (
                    <div key={index}>
                      <img
                          className="w-full h-full object-cover"
                          src={image}
                          alt={`Product Image ${index + 1}`}
                      />
                    </div>
                ))}
              </Slider>
            </div>
            <div className="h-full w-full md:col-span-2 xl:col-span-3 xl:p-14 flex flex-col gap-6 justify-center">
              <ProductInfo productInfo={productInfo} onBuyNow={openPaymentModal} />
            </div>
          </div>
          <div className="mt-10">
            <SliderItems source={bestSeller} title="Les Meilleurs Ventes" />
          </div>
        </div>

        {/* Payment Modal */}
        {isPaymentModalOpen && (
            <Payment
                isOpen={isPaymentModalOpen}
                onClose={closePaymentModal}
                products={[{ ...productInfo, quantity: 1 }]}
                total={productInfo.price}
            />
        )}
      </div>
  );
};

export default ProductDetails;
