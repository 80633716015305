import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";

const SliderItems = ({ source = [], title }) => { // Provide a default empty array to 'source' to avoid undefined
  const settings = {
    infinite: source.length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  // Check if the source has items
  if (!source || source.length === 0) {
    return <p>No products to display</p>;
  }

  return (
      <div className="w-full pb-16">
        <Heading heading={title} />
        <Slider {...settings}>
          {source.map((item) => (
              <div className="px-2" key={item._id}>
                <Product
                    _id={item._id}
                    img={item.img}
                    images={item.images}
                    productName={item.productName}
                    price={item.price}
                    color={item.color}
                    badge={item.badge}
                    promo={item.promo}
                    des={item.des}
                />
              </div>
          ))}
        </Slider>
      </div>
  );
};

export default SliderItems;
